$tp-border-radius__base: 4px;
$tp-border-radius__big: 6px;
$tp-border-radius__full: 50%;
$tp-border-radius__sides: 9999px;
$tp-breakpoint__large: 1025px;
$tp-breakpoint__large__value: 1025;
$tp-breakpoint__medium: 700px;
$tp-breakpoint__medium__value: 700;
$tp-breakpoint__small: 481px;
$tp-breakpoint__small__value: 481;
$tp-breakpoint__split-view__medium: 1060px;
$tp-breakpoint__split-view__medium__value: 1060;
$tp-breakpoint__split-view__small: 769px;
$tp-breakpoint__split-view__small__value: 769;
$tp-color__blue-100: #eaf6fa;
$tp-color__blue-200: #b3ebff;
$tp-color__blue-300: #79d2f2;
$tp-color__blue: #009fd9;
$tp-color__blue-500: #007fad;
$tp-color__blue-600: #005a7a;
$tp-color__indigo-100: #e8f1fd;
$tp-color__indigo-200: #cce1ff;
$tp-color__indigo-300: #96c2ff;
$tp-color__indigo: #5968e2;
$tp-color__indigo-500: #4f54b3;
$tp-color__indigo-600: #383c80;
$tp-color__purple-100: #f5efff;
$tp-color__purple-200: #dfccff;
$tp-color__purple-300: #d3baff;
$tp-color__purple: #a97ff0;
$tp-color__purple-500: #8a5ed6;
$tp-color__purple-600: #502095;
$tp-color__green-100: #e1fdf3;
$tp-color__green-200: #c6f7da;
$tp-color__green-300: #73e4a2;
$tp-color__green: #2db783;
$tp-color__green-500: #109e78;
$tp-color__green-600: #0f6648;
$tp-color__yellow-100: #fdf7e7;
$tp-color__yellow-200: #ffebb3;
$tp-color__yellow-300: #ffdd80;
$tp-color__yellow: #febe14;
$tp-color__yellow-500: #d48300;
$tp-color__yellow-600: #8a5500;
$tp-color__red-100: #ffeff0;
$tp-color__red-200: #ffd9d9;
$tp-color__red-300: #ffb0b0;
$tp-color__red: #ff5a5f;
$tp-color__red-500: #cc3553;
$tp-color__red-600: #76012c;
$tp-color__black-300: #676d73;
$tp-color__black: #2f3033;
$tp-color__gray-200: #fafafa;
$tp-color__gray-300: #e9eced;
$tp-color__gray: #d3d4d5;
$tp-color__white: #ffffff;
$tp-font-family__base: Mark, Avenir, Helvetica, Arial, sans-serif;
$tp-font-family__monospace: 'Source Code Pro', monospace;
$tp-font-weight__normal: 400;
$tp-font-weight__bold: 700;
$tp-font__title__1__size: 28px;
$tp-font__title__1__line-height: 32px;
$tp-font__title__1__weight: 700;
$tp-font__title__1__responsive__size: 40px;
$tp-font__title__1__responsive__line-height: 44px;
$tp-font__title__1__responsive__weight: 700;
$tp-font__title__2__size: 24px;
$tp-font__title__2__line-height: 28px;
$tp-font__title__2__weight: 700;
$tp-font__title__2__responsive__size: 32px;
$tp-font__title__2__responsive__line-height: 40px;
$tp-font__title__2__responsive__weight: 700;
$tp-font__title__3__size: 22px;
$tp-font__title__3__line-height: 28px;
$tp-font__title__3__weight: 700;
$tp-font__title__3__responsive__size: 24px;
$tp-font__title__3__responsive__line-height: 32px;
$tp-font__title__3__responsive__weight: 700;
$tp-font__title__4__size: 20px;
$tp-font__title__4__line-height: 28px;
$tp-font__title__4__weight: 700;
$tp-font__title__5__size: 18px;
$tp-font__title__5__line-height: 24px;
$tp-font__title__5__weight: 700;
$tp-font__title__6__size: 16px;
$tp-font__title__6__line-height: 24px;
$tp-font__title__6__weight: 700;
$tp-font__title__7__size: 14px;
$tp-font__title__7__line-height: 20px;
$tp-font__title__7__weight: 700;
$tp-font__title__8__size: 12px;
$tp-font__title__8__line-height: 18px;
$tp-font__title__8__weight: 700;
$tp-font__body__1__size: 16px;
$tp-font__body__1__line-height: 24px;
$tp-font__body__2__size: 14px;
$tp-font__body__2__line-height: 20px;
$tp-font__body__3__size: 12px;
$tp-font__body__3__line-height: 18px;
$tp-letter-spacing__loose: 1px;
$tp-letter-spacing__tight: -1px;
$tp-letter-spacing__extra-tight: -2px;
$tp-line-height__base: 1.6;
$tp-line-height__tight: 1.4;
$tp-line-height__loose: 1.9;
$tp-shadow__100: 0px 1px 3px rgba(0, 0, 0, 0.1);
$tp-shadow__200: 0px 2px 4px rgba(0, 0, 0, 0.15);
$tp-shadow__300: 0px 2px 7px rgba(0, 0, 0, 0.15);
$tp-shadow__400: 0px 2px 10px rgba(0, 0, 0, 0.2);
// @deprecated
$tp-shadow__card: 0 -1px 1px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.16);
$tp-space__1: 4px;
$tp-space__2: 8px;
$tp-space__3: 16px;
$tp-space__4: 24px;
$tp-space__5: 32px;
$tp-space__6: 64px;
$tp-space__7: 128px;
$tp-space__8: 256px;
$tp-wrap__max-width: 946px;
$tp-wrap__no-pad-width: 1010px;
$tp-z-index__modal: 200;
